import {Router} from "aurelia-router";
import {autoinject, TaskQueue} from "aurelia-framework";
import {FhirService} from "../resources/services/FhirService";
import {DialogMessages} from "../resources/services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {LocationService} from "../resources/services/LocationService";
import {ConfigService} from "../resources/services/ConfigService";
import {UserService} from "../resources/services/UserService";
import * as environment from "../../config/environment.json";
import {NitTools} from "../resources/classes/NursitTools";
import {fhirEnums} from "../resources/classes/fhir-enums";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {EncounterService} from "../resources/services/EncounterService";
import {SmileService} from "../resources/services/SmileService";
import {DialogService} from "aurelia-dialog";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

@autoinject
export class ExtendedNavigationView {
    router: Router;
    fhirService: FhirService;
    userService: UserService;
    dialogMessages: DialogMessages;
    i18n: I18N;
    locationService: LocationService;
    employeeGivenName: string;
    employeeFamilyName: string;
    userId: string;
    taskQueue: TaskQueue;

    public static AutoGenerateNonExistingPractitioners: boolean = false;

    // constructor(router: Router, configService, userService, dialogMessages : DialogMessages, i18n : I18N, locationService: LocationService, taskQueue : TaskQueue) {
    // constructor(configService, userService, dialogMessages : DialogMessages, locationService: LocationService) {
    constructor(router: Router, i18n: I18N, taskQueue: TaskQueue, dialogMessages: DialogMessages, userService: UserService, public encounterService: EncounterService, protected dialogService: DialogService) {
        this.router = router;
        this.i18n = i18n;
        this.fhirService = new FhirService();
        this.userService = userService;
        this.dialogMessages = dialogMessages;
        this.locationService = new LocationService();
        this.taskQueue = taskQueue;
    }

    async activate(params) {
        await ConfigService.Load(this.i18n);

        try {
            this.employeeGivenName = params.employeeGivenName;
            this.employeeFamilyName = params.employeeFamilyName;
            this.userId = params.userId;

            RuntimeInfo.Employee = {
                familyName: this.employeeFamilyName,
                givenName: this.employeeGivenName,
                userId: this.userId
            };

        } catch (e) {
            this.errorString = "in activate: " + (e.message || JSON.stringify(e));
        }

        FhirService.Hash = FhirService.EmbeddedUserHash;
        sessionStorage.setItem(environment.sessionName, FhirService.Hash);
    }

    async attached() {
        if (window.location.href.indexOf('embedded=1') === -1) {
            const start = window.location.origin + window.location.pathname;
            let search = window.location.search;

            if (!search) search = "?embedded=1";
            else search += "&embedded=1";

            window.location.href = `${start}${search}${window.location.hash}`;

            return;
        }

        sessionStorage.clear();
        sessionStorage.setItem(environment.sessionName, FhirService.EmbeddedUserHash);

        let userResult = await this.userService.impersonate(this.userId, this.employeeFamilyName, this.employeeGivenName);
        if (!this.userService.practitioner) {
            this.userService.isLoggedIn = false;
            UserService.IsLoggedIn = false;

            if (ExtendedNavigationView.AutoGenerateNonExistingPractitioners) {
                //#region create a practitioner for this user
                this.debug(`Auto-Generating Practitioner with identifier "${this.userId.toUpperCase()}"`);
                await this.locationService.fetch();

                const smileUserResult = await new SmileService().getRemoteUser(this.userId.toUpperCase());

                const prac: any = {
                    resourceType: "Practitioner",
                    id: NitTools.Uid(),
                    text: {
                        status: "generated",
                        div: `<div xmlns="http://www.w3.org/1999/xhtml"><div class="collapse">Auto Generated Practitioner ${this.userId.toUpperCase()}</div><div>${this.employeeFamilyName}, ${this.employeeGivenName}</div></div>`
                    },
                    active: true,
                    name: [
                        {family: this.employeeFamilyName, given: [this.employeeGivenName]}
                    ],
                    language: RuntimeInfo.Language,
                    identifier: [{
                            system: `${RuntimeInfo.SystemHeader}/smile-account-id`,
                            value: this.userId.toUpperCase()
                        },
                        {
                            system: `${RuntimeInfo.SystemHeader}/smile-user-role`,
                            value: 'user'
                        }
                    ]
                };

                if (smileUserResult?.pid) {
                    prac.identifier.push({
                        system: `${RuntimeInfo.SystemHeader}/smile-user-pid`,
                        value: smileUserResult.pid
                    });
                }

                const practitionerRole: any = {
                    id: NitTools.Uid(),
                    resourceType: fhirEnums.ResourceType.practitionerRole,
                    active: true,
                    location: [],
                    practitioner: {
                        reference: `Practitioner/${prac.id}`
                    }
                };

                if (RuntimeInfo.Features.autoUserCreation?.forcePasswordChange === true) {
                    practitionerRole.extension = [
                        {url: `${RuntimeInfo.SystemHeader}/authorization/needsPasswordChange`, valueString: "true"}
                    ];
                }

                if (!RuntimeInfo.DataProxy?.enabled) {
                    // only add the permissions, when the datapumping is not enabled, orelse we will run into errors
                    // when assigning the locations (not found)
                    this.locationService.wards.forEach(ward => {
                        practitionerRole.location.push({reference: "Location/" + ward.id});
                    });
                }

                let bundle = this.fhirService.getBundle([prac, practitionerRole], HTTPVerb.put, BundleType.transaction);
                try {
                    await this.fhirService.post(bundle);
                    this.userService.isLoggedIn = true;
                    await this.userService.impersonate(this.userId, this.employeeFamilyName, this.employeeGivenName);
                } catch (e) {
                    console.warn(e);
                }
                //#endregion
            } else {
                let msg = this.i18n.tr("embedded_user_not_found").replace("%USERNAME%", this.userService.username);
                this.dialogMessages.prompt(msg, this.i18n.tr("warning"), true);
                this.debug(`<p class="text-danger bg-danger">${msg}</p>`);
            }
        } else {
            sessionStorage.setItem(environment.sessionName, FhirService.Hash);
            this.userService.isLoggedIn = true;
            UserService.IsLoggedIn = true;
        }

        userResult.role = UserService.Role;
        userResult.practitioner = UserService.Practitioner;
        userResult.userId = UserService.UserName.toUpperCase(); // this.userService.userId.toUpperCase();

        this.needsNewPassword = userResult.isNew && ConfigService.cfg?.features?.autoUserCreation?.enabled === true && ConfigService.cfg?.features?.autoUserCreation?.forcePasswordChange === true;
    }

    needsNewPassword: boolean = false;
    _errorString: string = "";
    get errorString() {
        return this._errorString;
    }

    set errorString(value) {
        if (!value) {
            this._errorString = "";
            return;
        }

        if (value.indexOf('"') === 0) {
            value = value.substr(1);
        }

        if (value.indexOf('"') === value.length - 1) {
            value = value.substr(0, value.length - 1);
        }

        this._errorString += value + "<br />";
    }

    debug(vars, obj?) {
        if (ConfigService.Debug) {
            console.debug(vars, obj);
            this.errorString = vars;
        }
    }
}
